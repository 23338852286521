if ($.cookie("jwt")) {
  $.ajaxSetup({
    headers: { Authorization: "Bearer " + $.cookie("jwt") },
  });
}

function resize() {
  $(".equal .panel").height(
    Math.max.apply(
      null,
      $(".equal .panel").map(function () {
        return $(this).height();
      })
    )
  );
}

$(document).ready(function () {

  $('[data-toggle="tooltip"]').tooltip();
  $('[data-toggle="popover"]').popover({
    content: function () {
      if ($(this).data("target-content"))
        return $($(this).data("target-content")).html();
    },
    title: function () {
      if ($(this).data("target-title"))
        return $($(this).data("target-title")).html();
    },
  });
  $(document).on("click", function (e) {
    // did not click a popover toggle or popover
    if (
      $(e.target).data("toggle") !== "popover" &&
      $(e.target).parents(".popover.in").length === 0
    ) {
      $('[data-toggle="popover"]').popover("hide");
    }
  });
  // End - Bootstrap Script.

  $(".fancybox").fancybox();
  $(".fancybox-thumb").fancybox({
    helpers: {
      title: {
        type: "inside",
      },
    },
  });

  $.notifyDefaults({
    type: "success",
    placement: {
      from: "top",
      align: "center",
    },
    offset: 50,
    z_index: 1100,
  });
  // ฟังก์ชันตรวจสอบและปรับวันที่ให้อยู่ในช่วงที่กำหนด (ถ้ามี)
  function parseDateInput(datePattern) {
    const today = new Date();
  
    // ตรวจสอบรูปแบบ yyyy-mm-dd
    const isoDateMatch = datePattern.match(/^\d{4}-\d{2}-\d{2}$/);
    if (isoDateMatch) {
      const parsedDate = new Date(datePattern);
      return isNaN(parsedDate) ? null : parsedDate;
    }
  
    // ตรวจสอบรูปแบบ +/-Nd
    const relativeDateMatch = datePattern.match(/^([+-]\d+)(d)$/);
    if (relativeDateMatch) {
      const days = parseInt(relativeDateMatch[1], 10);
      today.setDate(today.getDate() + days);
      return today;
    }
  
    // คืนค่า null หากรูปแบบไม่รองรับ
    return null;
  }
  
  function triggerVueInput(input) {
    const event = new Event("input", { bubbles: true });
    input.dispatchEvent(event);
  }
  function handleDatepickerChange(input) {
    const value = $(input).val();
    const onCloseHandler = $(input).datepicker("option").onClose;
  
    if (onCloseHandler) {
      onCloseHandler.call(input, value);
    }
  }
  function validateDateInput(input) {
    const $input = $(input); // แปลง input เป็น jQuery Object
    let dateValue = $input.val(); // ดึงค่าจาก input
    let date = new Date(dateValue); 

    if (!dateValue.match(/^\d{4}-\d{2}-\d{2}$/)) {
        if(isNaN(date.getTime())){
          date = new Date();
        }
        $input.val(date.toISOString().split("T")[0]); // อัปเดตค่าใน input เป็นวันที่ปัจจุบัน
    }

    // ดึง minDate และ maxDate จาก datepicker properties
    const minDate = parseDateInput($input.datepicker("option", "minDate"));
    const maxDate = parseDateInput($input.datepicker("option", "maxDate"));

    // ตรวจสอบว่า date อยู่ในช่วง minDate และ maxDate
    if (minDate && date < minDate) {
        date = minDate; // หากน้อยกว่า minDate ให้ตั้งเป็น minDate
        $input.val(minDate.toISOString().split("T")[0]);
    }
    if (maxDate && date > maxDate) {
        date = maxDate; // หากมากกว่า maxDate ให้ตั้งเป็น maxDate
        $input.val(maxDate.toISOString().split("T")[0]);
    }

    // Trigger Vue's reactivity
    triggerVueInput(input);
  }
  // Set Script for Datepicker.
  $.datepicker.setDefaults({
    dateFormat: "yy-mm-dd",
  });
  
  $(".datepicker-from").datepicker({
    minDate: $(".datepicker-from").data("min-date") || "-365d",
    maxDate: "+0d",
    onClose: function (selectedDate) {
      //$(".datepicker-to").datepicker("option", "minDate", selectedDate);
      const fromDate = new Date(selectedDate);
      const toDateValue = $(".datepicker-to").val();

      // Validate datepicker-from
      validateDateInput(this);

      const adjustedFromDate = new Date($(this).val()); // Updated date after validation
      if (toDateValue) {
        const toDate = new Date(toDateValue);
        const maxAllowedToDate = new Date(adjustedFromDate);
        maxAllowedToDate.setFullYear(adjustedFromDate.getFullYear() + (reportInfo ? reportInfo.report_date_range_limit_years : 1) );
  
        // ตรวจสอบว่า maxAllowedToDate ไม่เกิน maxDate
        
        const globalMaxDate = parseDateInput($(".datepicker-to").datepicker("option", "maxDate"));
        const finalMaxAllowedToDate = new Date(Math.min(maxAllowedToDate.getTime(), globalMaxDate.getTime()));

        if (toDate > finalMaxAllowedToDate) {
          $(".datepicker-to").val(finalMaxAllowedToDate.toISOString().split("T")[0]);
          triggerVueInput($(".datepicker-to")[0]);
        } else if (adjustedFromDate > toDate) {
          $(".datepicker-to").val(adjustedFromDate.toISOString().split("T")[0]);
          triggerVueInput($(".datepicker-to")[0]);
        }
        
      }
      triggerVueInput(this);
    }
  }).on("change", function () {
    handleDatepickerChange(this);
  });

  $(".datepicker-to").datepicker({
    minDate: $(".datepicker-from").data("min-date") || "-365d",
    maxDate: $(".datepicker-to").data("max-date") || "+0d",
    onClose: function (selectedDate) {
      const toDate = new Date(selectedDate);
      const fromDateValue = $(".datepicker-from").val();
      // Validate datepicker-to
      validateDateInput(this);

      const adjustedToDate = new Date($(this).val()); // Updated date after validation
      if (fromDateValue) {
        const fromDate = new Date(fromDateValue);
        const minAllowedFromDate = new Date(adjustedToDate);
        minAllowedFromDate.setFullYear(adjustedToDate.getFullYear() - (reportInfo ? reportInfo.report_date_range_limit_years : 1));

        // ตรวจสอบว่า minAllowedFromDate ไม่ต่ำกว่า minDate
        const globalMinDate = parseDateInput($(".datepicker-from").datepicker("option", "minDate"));
        const finalMinAllowedFromDate = new Date(Math.max(minAllowedFromDate.getTime(), globalMinDate.getTime()));

        if (fromDate < finalMinAllowedFromDate) {
          $(".datepicker-from").val(finalMinAllowedFromDate.toISOString().split("T")[0]);
          triggerVueInput($(".datepicker-from")[0]);
        } else if (adjustedToDate < fromDate) {
          $(".datepicker-from").val(adjustedToDate.toISOString().split("T")[0]);
          triggerVueInput($(".datepicker-from")[0]);
        }
      }
      triggerVueInput(this);
    },
  }).on("change", function () {
    handleDatepickerChange(this);
  });

  // Set Default Validation Script.
  $.validator.setDefaults({
    errorElement: "span",
    errorClass: "help-block",
    validClass: "has-success",
    highlight: function (element, errorClass, validClass) {
      if (element.type == "checkbox" || element.type == "radio") {
        this.findByName(element.name)
          .addClass(errorClass)
          .removeClass(validClass);
      } else {
        $(element)
          .closest(".form-group")
          .removeClass("has-success has-feedback")
          .addClass("has-error has-feedback");
        $(element).closest(".form-group").find(".glyphicon").remove();
        $(element).after(
          $("<span />", {
            class: "glyphicon glyphicon-remove form-control-feedback",
          })
        );
      }
    },
    unhighlight: function (element, errorClass, validClass) {
      if (element.type == "checkbox" || element.type == "radio") {
        this.findByName(element.name)
          .removeClass(errorClass)
          .addClass(validClass);
      } else {
        $(element)
          .closest(".form-group")
          .removeClass("has-error has-feedback")
          .addClass("has-success has-feedback");
        $(element).closest(".form-group").find(".glyphicon").remove();
        $(element).after(
          $("<span />", {
            class: "glyphicon glyphicon-ok form-control-feedback",
          })
        );
      }
    },
    errorPlacement: function (error, element) {
      if (
        element.parent(".input-group").length ||
        element.prop("type") === "checkbox" ||
        element.prop("type") === "radio"
      ) {
        error.insertAfter(element.parent());
      } else {
        error.insertAfter(element);
      }
    },
  });
  // End - Set Default Validation Script.

  // Hamburger Menu - Scripts
  $(document).on("click", ".menu-hamburger .menu-open", function () {
    $(".menu-content").animate({
      right: 0,
    });
    $(this).fadeOut();
    $(".menu-hamburger .menu-close").fadeIn();
  });
  $(document).on("click", ".menu-hamburger .menu-close", function () {
    $(".menu-content").animate({
      right: "-60%",
    });
    $(this).fadeOut();
    $(".menu-hamburger .menu-open").fadeIn();
  });

  // Menu-DropDown - Scripts.
  $(document).on("click", ".menu-dropdown", function () {
    var parent = $(this);
    // if ( parent.is('.active') ) {
    if (parent.find("ul").is(":visible")) {
      // parent.removeClass('active');
      parent.find("ul").fadeOut();
    } else {
      // parent.addClass('active');
      parent.find("ul").fadeIn();
    }
  });
  // End - Menu-DropDown - Scripts

  // Click ho table row to tik checkbox inside.
  $(".tr-checkbox").on("click", function (e) {
    if ($(e.target).hasClass("tr-checkbox") || $(e.target).is("td")) {
      $(this)
        .find("input[type=checkbox]")
        .prop("checked", function () {
          return !this.checked;
        });
    }
  });
  $("table th").on("change", "[type=checkbox].checkbox-all", function () {
    var table = $(this).closest("table");
    table
      .find("tbody [type=checkbox]")
      .prop("checked", $(this).prop("checked"));
  });
  // End - Click ho table row to tik checkbox inside.

  // Set readonly to true or false when radio with etc input.
  $(".radio-with-etc input[type=radio]").change(function () {
    var box = $(this).closest(".radio-with-etc");
    var cur_val = $(this).val();
    var last_val = box.data("last-val");
    var target = box.data("etc-target");

    if (cur_val == last_val) $(target).attr("readonly", false);
    else $(target).val("").attr("readonly", true);
  });

  // Confirm before remove anything.
  $(".destroy-button").click(function () {
    $msg = $(this).data("message");
    if (!$msg)
      $msg = "กรุณายืนยันการลบข้อมูล ข้อมูลที่ถูกลบจะไม่สามารถเรียกกลับมาได้";

    if (confirm($msg)) $($(this).data("destroy")).submit();
  });
});

var loadingScreen = $("#preloader");

$(window).load(function () {
  window.READY = true;

  resize();

  $("#preloader").fadeOut("slow", function () {
    $(this).css("opacity", 0.5)
  });

  if (LOGGED)
    $.ajax({
      url: API2_URL + "logs",
      type: "POST",
      data: {
        company_id: COMPANY ? COMPANY.id : null,
        branch_id: BRANCH ? BRANCH.id : null,
      },
    });
});

function autoTextAreaSize() {
  var textarea = $("textarea");
  autosize.destroy(textarea);
  autosize(textarea);
}

var arincare = angular.module("ARINCARE", []);
window.isMobile = () => {
  if (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  ) {
    return true;
  }
  return false;
};

function checkPassword() {
  var password = $("#input-password").val();
  var password_confirmation = $("#input-password_confirmation").val();
  if (password != password_confirmation) {
    alert("รหัสผ่านไม่ตรงกัน กรุณากรอกรหัสผ่านใหม่");
    return false;
  }
}
