/**
 * Product2 javascript file
 * for product edit/create page
 */

var Product2Edit = {

    //Data
    tools: {
        loaded: false,
        company: {
            branches: [],
            product_groups: [],
            suppliers: []
        },
        bureauOfDrugControls: [],
        categories: [],
        treatmentClasses: [],
        manufacturers: [],
        units: [],
        ingredients: []
    },
    data: {
        changed: false,
        ok: false,
        reference_code: '',
        product: {}, // All product data
        productOrigin: {}, // Original product data

        general: {}, // Product general data send to api in submit()
        configuration: {},
        activate: 0,
    },
    newProduct: {
        reference_code: 'new',
        is_medicine: 1,
        activate: 1,
        loaded: true
        /* ... */
    },
    search: {
        keyword: '',
        products: [],
        setTimeout: undefined,
        ajax: undefined
    },
    protected: true,
    saving: false,
    ajax: undefined,
    unSaveAnswer: true,
    unSaveCallback: null,

    init: function (company) {
        let _this = this;
        var company_slug = (company ? company.slug : (this.tools.loaded ? this.tools.company.slug : null));

        if (!company_slug) {
            console.log('Can\'t init Product2Edit without company slug.');
            return;
        }

        this.endpoint = API3_URL + 'companies/' + company_slug + '/products';

        //Define Main element with jquery selector
        this.topTarget = $('#product-edit');
        this.search.form = $('#product-edit-search-form');
        this.search.target = $('#product-edit-search-list');
        this.generalForm = $('#product-edit-general-form');
        this.unSaveModal = $('#product-edit-unsave');

        this.productTypeaheadContainer = $('#product-typeahead-container');
        this.productTypeaheadSelect = $('#select_product_typeahead');
        this.productTypeaheadCheckbox = $('#product-typeahead-container input[name=is_all]');

        //loadingScreen will fadeOut in this.loadTools();
        //loadingScreen.fadeOut('slow');

        //Load tools data for editing (eg. units)
        this.loadTools();

        //Init child
        this.Storage.init();
        this.Unit.init();
        this.Barcode.init();
        this.Price.init();
        this.MInfo.init();
        this.Supplier.init();
        this.Print.init();

        if (location.hash && location.hash !== '#new') {
            //First load product list form api
            this.openProduct(location.hash.substr(1));
            this.productTypeaheadContainer.show();
        } else {
            this.setupCreate();
            this.productTypeaheadContainer.hide();
        }

        this.productTypeahead();
        this.typeahead();

        /** Listener */
        this.generalForm.on('change', 'input, select, textarea', function () {
            console.log('general data changed');
            this.receiveData();
            this.change(true);
        }.bind(this));
        $(window).on('hashchange', this.onHashChange.bind(this));

        $(window).bind('keydown', function (event) {
            if (event.ctrlKey && (event.which === 83)) {
                event.preventDefault();
                $('input, select, textarea').blur();
                if (!this.saving) this.submitOpenTab();
                console.log('Ctrl+S');
                return false;
            }
        }.bind(this));

        this.productTypeaheadCheckbox.change(function () {
            this.productTypeaheadSelect.focus();
        }.bind(this));

        //Warning unsave on change tab
        this.topTarget.find('a[data-toggle="tab"]').on('click', function (event) {
            var _this = this;

            if (Product2Edit.changed || Product2Edit.childChanged()) {
                Product2Edit.checkChanged(function () {
                    if (!Product2Edit.unSaveAnswer) {
                        console.log('Reset on change tab.');
                        Product2Edit.resetOpenTab();
                    }
                    setTimeout(function () {
                        _this.click();
                    }, 0);
                });
                return false;
            } else {
                setTimeout(function () {
                    autoTextAreaSize();
                }, 200)
            }
        });

        //Fix selectpicker lag
        this.topTarget.find('.selectpicker').change(function () {
            console.log('selectpicker change.');
            var _this = $(this);
            _this.selectpicker('destroy');
            _this.selectpicker('refresh');
        });

        //Validate input number
        this.topTarget.on('keypress', ':input', function (event) {
            var oldValue = event.target.value;
            setTimeout(function () {
                if (!event.target.value) event.target.value = oldValue;
            });
        }.bind(this));

        let btnAddWishlist = $('.btn-wishlist-add');
        btnAddWishlist.click(function(){
            _this.addToWishlist();
        });
        let btnRemoveWishlist = $('.btn-wishlist-remove');
        btnRemoveWishlist.click(function(){
            _this.removeFromWishlist();
        });

        //--------- แจ้งเตือนconfirmปิดการใช้งานสินค้า --------------
        $('#activate_checkbox').change(function(){
            if (!$(this).is(':checked')) {
                $(this).prop('checked', true);
                _this.deActivateProductModal();
            }
        });

        $('#deactivate-product-modal-delete-item').off('input');
        $("#deactivate-product-modal-delete-item").on("input", function(){
			let textInput = $(this).val();
			if (textInput === "ปิดใช้งาน") {
				$('#de-activate-product-modal-confirm_button').attr("disabled", false);
				$('#de-activate-product-modal-confirm_button').css({ 'background-color': '#67D786'});
			} else {
				$('#de-activate-product-modal-confirm_button').attr("disabled", true);
				$('#de-activate-product-modal-confirm_button').css({ 'background-color': '#CDCDCD'});
			}
		});  
    },
    deActivateProductModal: function() {
        loadingScreen.show();
		$.ajax({
            url: API3_URL + 'companies/' + COMPANY.slug + '/products/' + this.data.reference_code + '/confirm-data',
            type: 'GET',
            success: (d) => {
                let confirm_data = d.data
                $('#deactivate-product-modal').find('.modal-title').removeClass("text-status-COMPLETED").addClass('text-status-CANCELED02').html('ยืนยันการปิดใช้งานสินค้า');
                $('#deactivate-product-modal').find('.product_code').val(this.data.reference_code);
                $('#deactivate-product-modal').find('.modal-ref-code').html('รหัสสินค้า&nbsp;:&nbsp;'+confirm_data.product_reference_code);
                $('#deactivate-product-modal').find('.modal-prod-name').html('ชื่อสินค้า&nbsp;&nbsp;:&nbsp;'+ confirm_data.product_name);
                $('#deactivate-product-modal').find('.modal-quantity').html('จำนวนคงเหลือ&nbsp;:&nbsp;'+confirm_data.product_remaining_quantity+' '+confirm_data.smallest_unit_name);
                $('#deactivate-product-modal').find('#deactivate-product-modal-delete-item').val('');
                $('#deactivate-product-modal').modal('show');
            },
            error: function(d) {
                console.log('error' + d.statusCode);
            }
        })
        .always(() => {
            loadingScreen.fadeOut('slow');
        });
	},
    onHashChange: function () {
        if (location.hash.substr(1) !== this.data.reference_code) {
            var _this = this;

            this.checkChanged(callback);

            function callback() {
                _this.openProduct(location.hash.substr(1));
            }

            if (location.hash && location.hash !== '#new') {
                this.productTypeaheadContainer.show();
            } else {
                this.productTypeaheadContainer.hide();
                this.productTypeaheadSelect.val('');
            }
        }
    },

    loadTools: function () {

        loadingScreen.show();

        var _this = this;
        $.ajax({
            url: this.endpoint + '/tools',
            type: 'get',
            success: function (data) {
                _this.tools = data;
                _this.tools.loaded = true;

                // Child
                _this.Storage.renderTools();
                _this.MInfo.renderTools();
                _this.Supplier.renderTools();

                _this.renderData();
                autoTextAreaSize();
            },
            error: function () {
                console.log('error load tools');
                $.notify({
                    message: 'โหลดเครื่องมือการแก้ไขไม่สำเร็จ'
                }, {
                    type: 'danger',
                    placement: {
                        from: "top",
                        align: "center"
                    },
                    offset: 50
                });
            },
            complete: function () {
                loadingScreen.fadeOut('slow');
            }
        });
    },

    openProduct: function (reference_code) {
        if (reference_code === 'new') {
            this.setupCreate();
            return false;
        }

        if (this.ajax) this.ajax.abort();

        loadingScreen.show();

        var _this = this;
        this.ajax = $.ajax({
            url: this.endpoint + '/' + reference_code,
            type: 'get',
            success: function (data) {
                if (data.reference_code) {
                    if (!_this.search.keyword) _this.search.products = [data];

                    _this.setProductData(data);
                } else {
                    //No product

                    $.notify({
                        message: 'สินค้าที่เลือกไม่มีอยู่หรือยังไม่เปิดใช้งาน'
                    }, {
                        type: 'danger',
                        placement: {
                            from: "top",
                            align: "center"
                        },
                        offset: 50
                    });

                    _this.setupCreate();
                }
            },
            error: function () {
                console.log('error load product.');
                $.notify({
                    message: 'โหลดข้อมูลสินค้าไม่สำเร็จ'
                }, {
                    type: 'danger',
                    placement: {
                        from: "top",
                        align: "center"
                    },
                    offset: 50
                });
            },
            complete: function () {
                loadingScreen.fadeOut('slow');
            }
        });
    },

    setupCreate: function () {
        var _this = this;

        this.checkChanged(callback);

        function callback() {
            if (!_this.search.keyword) _this.search.products = [];
            _this.setProductData($.extend(true, _this.newProduct, {company_id: true}));

            location.hash = '#new';
            _this.loadStatistics();
        }
    },

    setProductData: function (data) {
        location.hash = '#' + data.reference_code;

        data.loaded = true;
        this.data.product = $.extend(true, {}, data);
        this.data.productOrigin = $.extend(true, {}, data);
        this.data.reference_code = data.reference_code;
        this.protected = !data.company_id;
        this.change(false);
        this.renderData();
        autoTextAreaSize();
    },

    reload: function () {
        this.checkChanged(callback);

        function callback() {
            Product2Edit.init();
        }
    },

    renderData: function () {
        var p = this.data.product;
        

        this.topTarget.find('#created_at').text(p.created_at ? p.created_at : 'n/a');
        this.topTarget.find('#updated_at').text(p.configuration ? p.configuration.updated_at : 'n/a');

        this.generalForm.find('input[name=reference_code]').val(p.reference_code);
        this.generalForm.find('input[name=name]').val(p.name);
        this.generalForm.find('input[name=short_name]').val(p.short_name);
        this.generalForm.find('input[name=nickname]').val(p.configuration ? p.configuration.nickname : '');
        this.generalForm.find('input[name=is_free]').prop('checked', (p.configuration ? p.configuration.is_free : false));
        this.generalForm.find('input[name=is_medicine]').prop('checked', p.is_medicine);
        this.generalForm.find('input[name=activate]').prop('checked', p.activate);

        this.handleChangeGenericName();

        /* WISHLIST */
        let btnAddWishlist = this.generalForm.find('#btn-wishlist-add');
        let btnRemoveWishlist = this.generalForm.find('#btn-wishlist-remove');

        btnAddWishlist.hide();
        btnAddWishlist.attr('product-id', p.id);
        btnAddWishlist.attr('product-reference-code', p.reference_code);
        btnAddWishlist.attr('wishlistitem-id', "0");

        btnRemoveWishlist.hide();
        btnRemoveWishlist.attr('product-id', p.id);
        btnRemoveWishlist.attr('product-reference-code', p.reference_code);

        if(p.wishlist_item == null){
            btnAddWishlist.show();
        }else{
            btnRemoveWishlist.attr('wishlistitem-id', p.wishlist_item.id);
            btnRemoveWishlist.show();
        }
        /* WISHLIST ENDS */

        let btnIsCatalog = this.generalForm.find('#btn-is-catalog');
        if(p.is_catalog)
        {
          btnIsCatalog.attr('product-id', p.id);
          btnIsCatalog.show();
        }

        if (p.deprecated_at) {
            this.topTarget.find('#deprecate-at').show();
            this.topTarget.find('#deprecate-at2').show();
            this.topTarget.find('#deprecate-at').find('span').text(p.deprecated_at);
        } else {
            this.topTarget.find('#deprecate-at').hide();
            this.topTarget.find('#deprecate-at2').hide();
            this.topTarget.find('#deprecate-at').find('span').text('');
        }

        this.topTarget.find('#header').show();

        // When product data is load success from api
        if (this.tools.loaded && p.loaded) {
            // Child
            this.Storage.render();
            this.Unit.render();
            this.Barcode.render();
            this.Price.render();
            this.MInfo.render();
            this.Supplier.render();
            this.Print.render();
        }

        this.renderProtected();
    },

    renderProtected: function() {
        if (this.protected) {
            //Is PCO
            this.topTarget.find('.protected').prop('disabled', true);
            this.topTarget.find('.protected-hide').css('display', 'none');
        } else {
            //Is PCU
            this.topTarget.find('.protected').prop('disabled', false);
            this.topTarget.find('.protected-hide').css('display', 'inherit');
        }
    },

    //Use for test case protection PCO data
    setProtected: function(protected) {
        this.protected = protected;
        this.renderData();
    },

    receiveData: function () {
        var p = this.data.product;
        var g = this.data.general;

        if (!p.configuration) p.configuration = {};

        p.name = g.name = this.generalForm.find('input[name=name]').val();
        p.short_name = g.short_name = this.generalForm.find('input[name=short_name]').val();
        p.configuration.nickname = this.data.configuration.nickname = this.generalForm.find('input[name=nickname]').val();
        p.configuration.is_free = this.data.configuration.is_free = this.generalForm.find('input[name=is_free]').is(':checked') ? 1 : 0;
        p.is_medicine = g.is_medicine = this.generalForm.find('input[name=is_medicine]').is(':checked') ? 1 : 0;
        p.activate = this.data.activate = this.generalForm.find('input[name=activate]').is(':checked') ? 1 : 0;

        //Checked data not empty
        this.data.ok = !!g.name;

    },

    change: function (changed) {
        this.data.changed = changed || this.childChanged();

        if (this.data.changed) {
            this.topTarget.find('#unsave').show();
        } else {
            this.topTarget.find('#unsave').hide();
        }
    },

    childChanged: function () {
        // Child
        return this.Storage.data.changed ||
            this.Unit.data.changed ||
            this.Barcode.data.changed ||
            this.MInfo.data.changed ||
            this.Price.data.changed ||
            this.Supplier.data.changed ||
            this.Print.data.changed;
    },

    checkChanged: function (callback) {
        var change = this.data.changed || this.childChanged();

        if (callback) {
            if (change) {
                this.unSaveModal.modal('show');
                this.unSaveCallback = callback;
            } else {
                callback();
            }
        }

        return change;
    },

    doUnsave: function (save) {
        this.unSaveAnswer = save;
        if (save) {
            this.submitOpenTab();
            console.log('Save change.');
        } else {
            this.runUnsaveCallback();
            console.log('Discard change.');
        }
    },

    runUnsaveCallback: function () {
        this.unSaveCallback();
        this.clearUnSaveCallback(false);
    },

    clearUnSaveCallback: function (rollbackTypeaheadText = true) {
        if (this.unSaveCallback) {
            this.unSaveCallback = null;
            this.unSaveModal.modal('hide');
        }

        if (rollbackTypeaheadText && this.productTypeaheadSelect.val()) {
            const text = this.data.product.name;
            this.productTypeaheadSelect.val(text);
        }
    },

    resetOpenTab: function () {
        this.topTarget.find('.tab-pane.active #save #reset-bt').click();
    },

    submitOpenTab: function () {
        this.topTarget.find('.tab-pane.active #save #save-bt').click();
    },

    submit: function (data, callback) {
        //popup required input
        $('#dummy-submit').click();

        this.receiveData();

        //Check required data
        if (!this.data.ok) {
            console.log('Incomplete product data');
            return false;
        }

        //Check is saving
        if (this.saving) return;
        else this.saving = true;

        //Merge data
        if (!data) data = {};
        data.general = $.extend({}, data.general, this.data.general);
        data.configuration = $.extend({}, data.configuration, this.data.configuration);
        data.activate = this.data.activate;

        //Create or update
        var endpoint = this.endpoint;
        var type;
        if (this.data.reference_code === 'new') {
            type = 'post'
        } else {
            endpoint += '/' + this.data.product.reference_code;
            type = 'put'
        }

        loadingScreen.show();

        var _this = this;
        $.ajax({
            url: endpoint,
            type: type,
            data: JSON.stringify(data),
            contentType: "application/json",
            success: function (data) {

                if (_this.unSaveCallback) {
                    _this.runUnsaveCallback();
                }

                _this.setProductData(data.product);

                $.notify({
                    message: 'บันทึกข้อมูลแล้ว'
                }, {
                    type: 'success',
                    placement: {
                        from: "top",
                        align: "center"
                    },
                    offset: 50
                });

                if (callback) callback();
            },
            error: function () {
                console.log('error');
                $.notify({
                    message: 'บันทึกไม่สำเร็จ'
                }, {
                    type: 'danger',
                    placement: {
                        from: "top",
                        align: "center"
                    },
                    offset: 50
                });
            },
            complete: function () {
                _this.saving = false;
                loadingScreen.fadeOut('slow');
            }
        });
    },

    productTypeahead: function () {
        const _this = this;
        this.productTypeaheadSelect.typeahead({
            onSelect: function(item){
                if (item.value !== _this.data.reference_code) {
                    if (_this.data.changed) {
                        _this.checkChanged(callback);

                        function callback() {
                            _this.openProduct(item.value);
                        }
                    } else {
                        location.hash = '#' + item.value;
                    }
                }

                setTimeout(() => {
                    let text = _this.productTypeaheadSelect.val();
                    text = text.replace(/ - (PCO|PCU)\w+/g, '');
                    _this.productTypeaheadSelect.val(text);
                }, 0);
            },
            ajax: {
                url: `${API3_URL}companies/${COMPANY.slug}/products`,
                displayField : 'full_name',
                valueField: "reference_code",
                dataType: 'JSON',
                type: 'GET',
                preDispatch: function (query) {
                    const is_all = _this.productTypeaheadCheckbox.is(':checked') ? 1 : 0;
                    return {
                        product_keyword: query,
                        form_page : 'product_list',
                        is_all,
                        is_deprecated: 1
                    }
                },
                preProcess : function (data) {
                    return data.data.map(p => Object.assign(p, { full_name: !p.reference_code ? p.name : `${p.name} - ${p.reference_code}` }))
                }
            }
        });
    },

    typeahead: function () {
        this.generalForm.find('input[name=replacement_name]').typeahead({
            onSelect: function (item) {
                this.generalForm.find('input[name=replacement_code]').val(item.value);
                this.generalForm.find('input[name=replacement_name]').val(item.text);
                //console.log(item);
            }.bind(this),
            items: 12,
            ajax: {
                url: this.endpoint,
                timeout: 300,
                valueField: "reference_code",
                displayField: "name",
                triggerLength: 1,
                method: "get",
                loadingClass: "loading-circle",
                preDispatch: function (query) {
                    return {
                        q: query
                    }
                },
                preProcess: function (data) {
                    if (data.success === false) {
                        return false;
                    }
                    return data.data;
                }
            }
        });
    },

    //Dummy submit
    propSubmit: function (event) {
        event.preventDefault();
    }, //propSubmit

    addToWishlist: function(){
        let btnAddWishlist = $('#btn-wishlist-add');
        let companySlug = btnAddWishlist.attr('company-slug');
        let productCode = btnAddWishlist.attr('product-reference-code');
        let username = btnAddWishlist.attr('username');

        wishlistFunction.add(btnAddWishlist, companySlug, productCode, username, '', false);
        let btnRemoveWishlist = $('#btn-wishlist-remove');
        btnRemoveWishlist.attr('wishlistitem-id', 'require-update').show();
        btnAddWishlist.hide();
    }, //addToWishlist

    removeFromWishlist: function(){
        let btnRemoveWishlist = $('#btn-wishlist-remove');
        let wishlistId = btnRemoveWishlist.attr('wishlistitem-id');
        let productCode = btnRemoveWishlist.attr('product-reference-code');
        let companySlug = btnRemoveWishlist.attr('company-slug');
        let username = btnRemoveWishlist.attr('username');

        if(wishlistId == 'require-update')
            wishlistId = $('#btn-wishlist-add').attr('wishlistitem-id');

        wishlistFunction.remove(btnRemoveWishlist, wishlistId, companySlug, productCode, false);
        $('#btn-wishlist-add').show();
        btnRemoveWishlist.hide();
    }, //removeFromwISHLIST

    handleChangeGenericName: function () {
        const p = this.data.product;
        this.generalForm.find('input[name=generic_name]').val(p.ingredients && p.ingredients.length ? p.ingredients.map(ingredient => ingredient.name).join(' + ') : '');
    },
    reset:function(){
        this.openProduct(location.hash.substr(1));
    },
    loadStatistics: function () {

        var companies_slug = COMPANY.slug;

        $.ajax({
            url: API3_URL + 'companies/' + companies_slug + '/products/statistics',
            type: 'get',
            success: function(data) {
                if (data.is_limited_pcu) {
                    $('#action button#save-bt').attr('disabled', true);
                }
            }
        });
    }
};
